import moment from 'moment'
/* Function for deep copy Object
   params:
      obj: Object
      return: cloned Object
*/
export let deepCopyObject = obj => JSON.parse(JSON.stringify(obj))

/* Function for download file
   params:
      obj: url file
      return: null
*/
export let downloadFile = (url, fileName = true) => {
  var link = document.createElement('a')
  link.setAttribute('download', fileName)
  link.href = url
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export let downloadFileByUrl = url => {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.setAttribute('download', true)
  downloadLink.click()
}

export let getDateOneYerAgoAndToday = () => {
  let today = new Date()
  let yearsAgo = new Date()
  yearsAgo.setFullYear(today.getFullYear() - 1)
  return { today, yearsAgo }
}

export let getDateOneMonthAgoAndToday = () => {
  let today = new Date()
  let monthAgo = new Date()
  monthAgo.setMonth(today.getMonth() - 1)
  return { today, monthAgo }
}

export let getDateSubtractDays = day => {
  const _date = new Date()
  _date.setDate(_date.getDate() - day)
  return _date
}

export let getDateSubtractYears = year => {
  const _date = new Date()
  _date.setFullYear(_date.getFullYear() - year)
  return _date
}

export let removeAllSumbolsExceptNumbers = value => {
  return value.replace(/[^0-9]/gim, '')
}

export let getCodePhone = phone => {
  return phone.replace(/\s+/g, '').slice(1, 4)
}

export let removeLocalStorage = key => {
  window.localStorage.removeItem(key)
}

export let setLocaleStorage = (key, value) => {
  window.localStorage.setItem(key, value)
}

export let getLocaleStorage = key => {
  return window.localStorage.getItem(key)
}

let checkDisabledDate = (disabledDates, dateString) => {
  let from = new Date(disabledDates.from),
    to = new Date(disabledDates.to),
    currentDateObj = new Date(dateString)
  return currentDateObj >= to && currentDateObj <= from
}

let checkFullDate = arrayValues => {
  return arrayValues.length === 3 && arrayValues[2].length === 4
}

let validDateStringFormat = (inputDate, disabledDates) => {
  let arrayValues = inputDate.split('/')
  let isFullDate = checkFullDate(arrayValues)
  let dateString = `${arrayValues[2]}-${arrayValues[1]}-${arrayValues[0]}`
  let validDate = isFullDate && checkDisabledDate(disabledDates, dateString)

  return validDate && dateString
}

export let checkValidDate = (date, disabledDates) => {
  if (date instanceof Date) {
    return true
  }
  let validDateString = validDateStringFormat(date, disabledDates)
  return validDateString && !isNaN(Date.parse(validDateString))
}

export let redirectTo = url => {
  window.location.href = url
}

export let formatedDateDDMMYYY = date => moment(date).format('DD/MM/YYYY')

export const formatDate = (date, format = 'DD/MM/YYYY') => moment(date).format(format)

export let isPageByNameRoute = route => {
  return window.location.href.indexOf(route) > 0
}

export let getCookie = name => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export let setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export let getCookies = cname => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export let scrollToElementById = id => {
  let sectionToScroll = document.getElementById(id)
  try {
    window.scrollTo({
      top: sectionToScroll.offsetTop,
      behavior: 'smooth',
    })
  } catch (e) {
    throw new Error(`Element with id -> '${id}' doesn't exist on this page.`)
  }
}

export let getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/windows phone/i.test(userAgent)) {
    return ['android']
  }

  if (/android/i.test(userAgent)) {
    return ['android']
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return ['ios']
  }

  return ['android', 'ios']
}

export let onlyNumbers = str => {
  return /^[0-9]+$/.test(str)
}

export const setTargetBlank = selector => {
  const links = document.querySelector(selector).getElementsByTagName('a')

  for (let i = 0; i < links.length; i++) {
    if (links[i].getAttribute('href').includes(links[i].host)) {
      links[i].setAttribute('target', '_blank')
      links[i].setAttribute('rel', 'noreferrer')
    }
  }
}

export const isCreditCardExpired = (expirationMonth, expirationYear) =>{
  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed

  // Convert expiration month and year to numbers
  expirationMonth = parseInt(expirationMonth);
  expirationYear = parseInt(expirationYear);

  // Check if the expiration year is in the past
  if (expirationYear < currentYear) {
    return true; // Card has expired
  } else if (expirationYear === currentYear) {
    // If expiration year is the current year, check the month
    return expirationMonth < currentMonth;
  } else {
    return false; // Card is still valid
  }
}

export const formatDateTimezone = (date) => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss')
}
